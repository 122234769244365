export const ACCOUNT_NUMBERS = {
    MYCARD: 357,
    RINCONADA: 44,
    TERRAVIVA: 397,
}

export const PAYMENT_PLANS_ENABLED_ACCOUNTS = new Set([
    ACCOUNT_NUMBERS.RINCONADA,
    ACCOUNT_NUMBERS.TERRAVIVA,
])

export const CUSTOM_SALES_BY_ITEM_REPORT = new Set([ACCOUNT_NUMBERS.MYCARD])
