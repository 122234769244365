export enum Currency {
    MXN = 'MXN',
    USD = 'USD',
}

export const isUSD = (currency: Currency): currency is Currency.USD =>
    currency === Currency.USD

export const isMXN = (currency: Currency): currency is Currency.MXN =>
    currency === Currency.MXN

export const getCurrencyOptions = (primaryCurrency: Currency): Currency[] => {
    if (isUSD(primaryCurrency)) return [Currency.USD]
    return [Currency.MXN, Currency.USD]
}
