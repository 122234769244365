import { CustomFieldWithOptions } from 'lib/models/customField'
import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = `/custom-fields`

const baseKey = 'customFields'

export const customFieldQueryKeys = {
    list: [baseKey],
    usage: [baseKey, 'usage'],
    invalidateAll: [baseKey],
}

export const customFieldService = {
    getCustomFields: async () => makeApiRequest<GetCustomFieldsData>({ url }),
    getCustomFieldUsage: async () =>
        makeApiRequest<GetCustomFieldUsageData>({ url: `${url}/usage` }),
    postCustomField: async (body: PostCustomFieldBody) =>
        makeApiRequest({ url, method: 'POST', body }),
    putCustomField: async (body: PutCustomFieldBody) =>
        makeApiRequest({ url: `${url}/${body.custom_field_id}`, method: 'PUT', body }),
    deleteCustomField: async (customFieldId: number) =>
        makeApiRequest({ url: `${url}/${customFieldId}`, method: 'DELETE' }),
}

type GetCustomFieldUsageData = {
    customFieldUsage: Record<
        number,
        | { invoice_count: number; subscription_count: number; customer_count: number }
        | undefined
    >
}

type GetCustomFieldsData = {
    customFields: CustomFieldWithOptions[]
}

export type PostCustomFieldBody = {
    label: string
    type: string
    for_invoices: boolean
    for_customers: boolean
    can_filter: boolean
    options: { value: string; color: string }[]
}

export type PutCustomFieldBody = {
    custom_field_id: number
    label: string
    for_invoices: boolean
    for_customers: boolean
    can_filter: boolean
    options: {
        custom_field_select_option_id: number | null
        value: string
        color: string
        to_delete: boolean
    }[]
}
